<template>

	<PillMenuBar :navigationItems="menuItems" headerType="main">
		
	</PillMenuBar>

	
	<ScrollPanel style="width:100%;height:800px;">
	<div class="tab-div">
		
	<router-view v-slot="{ Component }">

		<keep-alive><component :is="Component"/></keep-alive>

	</router-view>
	
	<Overview v-show="$route.path =='/marketIndex'"/>
	
	</div>
</ScrollPanel>
</template>

<script>
import TabMenu from 'primevue/tabmenu';
import Overview from '../components/marketIndex/Overview';
import PillMenuBar from '../components/menu/PillMenuBar'

import ScrollPanel from 'primevue/scrollpanel';
import ToggleButton from 'primevue/togglebutton';


export default {
	name: 'MarketIndex',
	data() {
		return {
			
			justMounted: false,
			active: false,
			menuItems: [
				{label: "Index Overview", to: '/marketIndex'},
				
				
			]
		}
	},
	components: {
		TabMenu,
		
		Overview,//: defineAsyncComponent(() => import('../components/equity/Overview')),
		PillMenuBar,
		
		ToggleButton,
		ScrollPanel,
	},
	created() {
	
	},
	computed: {
		
		
		
	},
	
	mounted() {
		this.justMounted = true;
		
		
	},
	activated() {
		this.active = true;
		console.debug("activated Equity");
		// if( !this.justMounted && this.$route.params.selectedSymbol) {
		// 	this.setSelectedSymbolFromQM(this.$route.params.selectedSymbol);
		// }
		this.justMounted = false;
	},
	deactivated() {
		console.debug("deactivated Equity");
		this.justMounted = false;
		this.active = false;
	},
	
  // when route changes and this component is already rendered,
  // the logic will be slightly different.
	// async beforeRouteUpdate(to, from) {
	// 	this.post = null
	// 	try {
	// 	this.post = await getPost(to.params.id)
	// 	} catch (error) {
	// 	this.error = error.toString()
	// 	}
	// },

	methods: {
		
	}
	
}
</script>

<style scoped>
 .pill-menu-bar {
	margin-left: 2rem;
    width: calc(100% - 5rem);
 }

 .tab-div {
	/* background: #1f2d40; */
    padding: 1rem;
    border: 0 none;
    /* color: rgba(255, 255, 255, 0.87); */
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
 }

</style>