<template>
  <div class="tab-content" >
    <keep-alive>
    <AdvChart securityType="INDEX" :selectedSymbol="selectedSymbol" key="indexChart"   />
    </keep-alive>
  </div>
  
</template>



<script>
import AdvChart from '../charts/highcharts/AdvChart';
export default {
  data(){
    return {

    }
  },
  components: {
    AdvChart
  },
  computed : {
    selectedSymbol() {
      return this.$store.state.selectedMarketIndex.symbol;
    }
  }
}
</script>

<style scoped>
.tab-content {
	/* background:#17212f; */
	padding:10px;
}
</style>
